<template>
  <div class="component content" v-if="insight">
    <div v-if="insight.type === 'COMPLETE'">
      <div class="block">
        <span class="insight-tenant">{{ insight.tenant }}</span
        ><br />
        <span class="transaction-date"> {{ insight.dateSold }} </span><br />
        {{ insight.streetAddress }}, {{ insight.city }}, {{ insight.state
        }}<br />
        {{ insight.squareFootage }} SF | {{ insight.landAcres }} AC
      </div>
      <div class="block">
        Price: {{ insight.soldPrice }}
        <span v-if="insight.soldPriceSpf">({{ insight.soldPriceSpf }} PSF)</span
        ><span v-if="insight.discountPercentage"
          >, discount {{ insight.discountPercentage }}
        </span>
        <br />
        Cap rate: {{ insight.soldCapRate }}<br />
        NOI: {{ insight.noi }}<br />
        <span v-if="insight.daysOnMarket"
          >Days on market: discount {{ insight.daysOnMarket }}
        </span>
      </div>
      <div class="block">
        Lease type: {{ insight.leaseType }}<br />
        Term remaining:
        {{ insight.leaseExpiration | moment("from", "now", true) }}<br />
        <span v-if="insight.numberOfOptions"
          >Options: {{ insight.numberOfOptions }}
          {{ insight.leaseOptionLength }}-year options
        </span>
        <br />
        <span v-if="insight.rentalEscalations"
          >Rental escalations: {{ insight.rentalEscalations }}<br />
        </span>
      </div>
    </div>
    <div v-else>
      <div class="block">
        <span class="insight-tenant">{{ insight.tenant }}</span
        ><br />
        <span class="transaction-date"> {{ insight.dateSold }} </span><br />
        {{ insight.streetAddress }}, {{ insight.city }}, {{ insight.state
        }}<br />
        {{ insight.squareFootage }} SF | {{ insight.landAcres }} AC
      </div>
      <div class="block">
        Price: {{ insight.askPrice }}
        <span v-if="insight.discountPercentage"> </span>
        <br />
        Cap rate: {{ insight.askCapRate }}<br />
        NOI: {{ insight.noi }}<br />
        <span v-if="insight.daysOnMarket"
          >Days on market: discount {{ insight.daysOnMarket }}
        </span>
      </div>
      <div class="block">
        Lease type: {{ insight.leaseType }}<br />
        Term remaining:
        {{ insight.leaseExpiration | moment("from", "now", true) }}<br />
        <span v-if="insight.numberOfOptions"
          >Options: {{ insight.numberOfOptions }}
          {{ insight.leaseOptionLength }}-year options
        </span>
        <br />
        <span v-if="insight.rentalEscalations"
          >Rental escalations: {{ insight.rentalEscalations }}<br />
        </span>
        <span v-if="insight.dateListed"
          >Date listed: {{ insight.dateListed }}<br />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsightInfoComponent",
  props: {
    insight: Object,
  },
};
</script>

<style scoped>
span.insight-tenant {
  font-weight: bold;
}

.transaction-date {
  color: gray;
  font-size: 0.9rem;
}
</style>
