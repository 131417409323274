<template>
  <div class="RecentTransactionsComponent">
    <div class="transaction component content" v-for="(insights, date) in groupedRecentTransactions" :key="date">
      <div class="transaction-date">
        {{ date }}
      </div>
      <div class="transactions">
        <div class="transaction-item" v-for="insight in insights" :key="insight.id">
          <div class="tenant">
            <a href="#" v-on:click="itemClicked(insight)">{{
              insight.tenant
            }}</a>
          </div>
          <div class="state">
            {{ stateToAbbreviation(insight.state) }}
          </div>
          <div class="time-remaining">
            {{ timeRemaining(insight) }}
          </div>
          <div v-if="insight.type === 'COMPLETE'" class="sold-price">
            ${{ moneyShort(insight.soldPrice) }}
          </div>
          <div v-else class="sold-price">
            ${{ moneyShort(insight.askPrice) }}
          </div>
          <div v-if="insight.type === 'COMPLETE'" class="sold-cap-rate">
            {{ insight.soldCapRate }}
          </div>
          <div v-else class="sold-cap-rate">
            <span v-if="insight.askCapRate">{{ insight.askCapRate }}
            </span>
            <span v-else>N/A</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";

const moment = require("moment");
export default {
  name: "RecentTransactionsComponent",
  props: {
    recentTransactions: Array,
  },
  computed: {
    groupedRecentTransactions() {
      return this.recentTransactions
        .sort((a, b) =>
          a.type === "COMPLETE"
            ? moment(a.dateSold, "MM/DD/YYYY") -
            moment(b.dateSold, "MM/DD/YYYY")
            : moment(a.dateListed, "MM/DD/YYYY") -
            moment(b.dateListed, "MM/DD/YYYY")
        )
        .reduce((acc, item) => {
          // convert string dateSold in format MM/DD/YYYY to Date object
          let date = item.type === "COMPLETE" ? item.dateSold : item.dateListed;
          const key = item.type === "COMPLETE" ? moment(date, "MM/DD/YYYY").format("MMM YYYY") : item.tenant;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});
    },
  },
  data: () => {
    return {
      transitionDirection: "right",
    };
  },
  methods: {
    stateToAbbreviation: utils.stateToAbbreviation,
    timeRemaining(insight) {
      if (!insight.leaseExpiration) {
        return "";
      }
      const diff = moment(insight.leaseExpiration, "MM/DD/YYYY").fromNow(true);
      return diff.slice(0, diff.indexOf(" ") + 2);
    },
    moneyShort(money) {
      return Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 1,
        currency: "USD",
      }).format(Number(money.replace(/[^0-9.-]+/g, "")));
    },
    itemClicked: function (item) {
      let clickedItemId = item.id;
      let payload = {
        type: "template_submit",
        screenId: this.$store.state.activeScreenId,
        buttonId: clickedItemId,
        data: {
          ...this.$store.state.inputs,
          submit: clickedItemId,
        },
      };
      if (clickedItemId in this.$store.state.screens) {
        this.$store.commit("setInputValue", {
          inputId: clickedItemId,
          value: item,
        });
        this.$store.commit("setActiveScreen", clickedItemId);
        payload.data.history = this.$store.state.history;
        this.$store.commit("emitResponse", {
          payload: JSON.stringify(payload),
          socket: this.$socket,
          transitionDirection: this.transitionDirection,
        });
      } else {
        payload.data.history = this.$store.state.history;
        this.$store.commit("submitResponse", {
          payload: JSON.stringify(payload),
          socket: this.$socket,
          transitionDirection: this.transitionDirection,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/skin.scss";

.transaction-date {
  color: gray;
  font-size: 0.9rem;
}

.transactions {
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.transaction-item {
  display: grid;
  grid-template-columns: 1fr 3rem 3rem 4rem 4rem;
}

.transaction-item>div {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.2rem 0.3rem;
  overflow: hidden;
}

.time-remaining,
.sold-price,
.sold-cap-rate {
  text-align: right;
}
</style>
