<template>
    <div class="RadioSelect control" :class="this.classes">
        <label class="radio" v-for="option in parsedOptions" :key="option.id">
            <input type="radio" :value="option.id" v-model="inputValue">
            {{ option.label }}
        </label>
    </div>
</template>
    
<script>
import BaseValue from "armory-sdk/src/components/base/BaseValue.vue";

export default {
    name: "RadioSelect",
    extends: BaseValue,
    props: {
        options: {
            type: String,
            required: true
        },
        classes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        parsedOptions() {
            return JSON.parse(this.options);
        },
    },
};
</script>
    
<style scoped>
.RadioSelect {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.RadioSelect>label {
    margin-left: 0;
}

input[type='radio'] {
    width: 22px;
    height: 22px;
}
</style>