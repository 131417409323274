<template>
  <div id="category-select" class="component content">
    <button
      class="button is-responsive is-info is-light"
      :class="{ 'is-light': !selectedCategories.includes(category) }"
      v-for="category in categories"
      :key="category"
      v-on:click="setCategory(category)"
    >
      {{ category }}
    </button>
    <button
      class="button is-responsive is-info is-light"
      v-on:click="allCategories()"
    >
      All
    </button>
    <button
      class="button is-responsive is-info is-light"
      v-on:click="noCategory()"
    >
      Clear
    </button>
  </div>
</template>

<script>
export default {
  name: "CategorySelectComponent",
  props: {
    componentId: String,
    cloudSelectInputId: String,
    categories: Array,
    locations: Array,
  },
  data() {
    return {
      selectedCategories: [],
    };
  },
  methods: {
    setCategory(category) {
      const existingLocations =
        this.$store.state.inputs[this.cloudSelectInputId].value || [];
      let value;

      if (this.selectedCategories.includes(category)) {
        this.selectedCategories = this.selectedCategories.filter(
          (c) => c !== category
        );
        value = this.locations
          .filter(
            (location) =>
              !location.category.includes(category) &&
              existingLocations.includes(location.name)
          )
          .map((location) => location.name);
      } else {
        this.selectedCategories = [...this.selectedCategories, category];
        value = this.locations
          .filter(
            (location) =>
              location.category.includes(category) ||
              existingLocations.includes(location.name)
          )
          .map((location) => location.name);
      }

      this.$store.commit("setInputValue", {
        inputId: this.cloudSelectInputId,
        value: {
          value: value,
        },
      });
      this.$store.commit("setInputValidation", {
        inputId: this.cloudSelectInputId,
        valid: value.length > 0,
      });
    },
    noCategory() {
      this.selectedCategories = [];
      this.$store.commit("setInputValue", {
        inputId: this.cloudSelectInputId,
        value: {
          value: [],
        },
      });
      this.$store.commit("setInputValidation", {
        inputId: this.cloudSelectInputId,
        valid: false,
      });
    },
    allCategories() {
      this.selectedCategories = [...this.categories];
      let value = this.locations.map((location) => location.name);
      this.$store.commit("setInputValue", {
        inputId: this.cloudSelectInputId,
        value: {
          value: value,
        },
      });
      this.$store.commit("setInputValidation", {
        inputId: this.cloudSelectInputId,
        valid: value.length > 0,
      });
    },
  },
};
</script>

<style scoped>
#category-select {
  display: flex;
  margin-bottom: 1.5rem;
  gap: 0.2rem;
  justify-content: flex-start;
  flex-wrap: wrap;
}
button {
  box-shadow: none !important;
  border-radius: 30px !important;
  min-width: 5rem;
  font-size: 0.75rem !important;
}
</style>
