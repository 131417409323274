<script>
import Armory from "armory-sdk/src/Armory";

import GoogleMapsComponent from "./components/GoogleMapsComponent";
import RecentTransactionsComponent from "./components/RecentTransactionsComponent.vue";
import InsightInfoComponent from "@/components/InsightInfoComponent";
import CategorySelectComponent from "@/components/CategorySelectComponent";
import AvailableSchedules from "@/components/AvailableSchedules.vue";
import RadioSelect from "@/components/RadioSelect.vue";
import RangeSlider from "@/components/RangeSlider.vue";
export default {
  name: "App",
  extends: Armory,
  methods: {
    getCustomComponents() {
      return {
        GoogleMapsComponent,
        RecentTransactionsComponent,
        InsightInfoComponent,
        CategorySelectComponent,
        AvailableSchedules,
        RadioSelect,
        RangeSlider,
      };
    },
  },
};
</script>
