<template>
  <div class="RangeSlider component content">
    <b-slider
      v-if="inputValue"
      v-model="inputValue"
      :min="minValue"
      :max="maxValue"
      :step="step"
      class="custom-slider"
    >
    </b-slider>
    <div
      class="range-values"
      v-text="
        `Selected Range: ${inputValue[0]}% - ${inputValue[1]}${
          inputValue[1] === this.$props.maxValue ? '+' : ''
        }%`
      "
    ></div>
  </div>
</template>

<script>
import RangeSlider from "armory-sdk/src/components/RangeSlider.vue";

export default {
  name: "RangeSlider",
  extends: RangeSlider,
};
</script>
