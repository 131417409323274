<template>
  <div
    :id="componentId"
    class="GoogleMapsComponent"
    style="display: flex; justify-content: center"
    v-if="this.coordinates != null"
  >
    <GmapMap
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
      }"
      :center="this.coordinates"
      :zoom="15"
      map-type-id="satellite"
      style="width: 100%; height: 30vh"
    >
      <GmapMarker :position="coordinates">
        <GmapInfoWindow>
          <div>{{ address }}</div>
        </GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "GoogleMapsComponent",
  props: {
    address: String,
    componentId: String,
  },
  computed: {
    apiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
  },

  data() {
    return {
      coordinates: null,
    };
  },
  mounted() {
    // clean address by removing non-alphanumeric characters, spaces and commas
    const cleanedAddress = this.address.replace(/[^a-zA-Z0-9 ,]/g, "");
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${cleanedAddress}&key=${this.apiKey}`
    )
      .then((response) => response.json())
      .then((data) => (this.coordinates = data.results[0].geometry.location));
  },
};
</script>

<style scoped></style>
