import { render, staticRenderFns } from "./RecentTransactionsComponent.vue?vue&type=template&id=edc5d860&scoped=true"
import script from "./RecentTransactionsComponent.vue?vue&type=script&lang=js"
export * from "./RecentTransactionsComponent.vue?vue&type=script&lang=js"
import style0 from "./RecentTransactionsComponent.vue?vue&type=style&index=0&id=edc5d860&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edc5d860",
  null
  
)

export default component.exports